/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate, useSearchParams } from "react-router-dom";
import "./Home.css";
import Modal from 'react-bootstrap/Modal';
import Page from "../components/Page";
import { useHeGame } from "../lib/GameProvider";
import GiftModal from "../components/GiftModal";

const companyName = process.env.REACT_APP_COMPANY_NAME || "[COMPANY_NAME]"
const serviceName = process.env.REACT_APP_SERVICE_NAME || "[SERVICE_NAME]"
const refNumber = process.env.REACT_APP_REF_NO || "[REF_NO]"
const website = process.env.REACT_APP_WEBSITE || "[WEBSITE]"
const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL || "[SUPPORT_EMAIL]"
const serviceMobile = process.env.REACT_APP_SERVICE_MOBILE_NO || "[SERVICE_MOBILE_NO]"

export default function HomePage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // wrap the functionality into a provider so we can use it across components
  const {
    isLoggedIn,
    loading,
    btnLoading,
    message,
    error,
    showModal,
    setShowModal,
    handleSubscription,
  } = useHeGame({ navigate, searchParams })

  // If logged in then redirect to home
  if (isLoggedIn) {
    navigate(`/games?${searchParams.toString()}`)
  }

  function handleModalClose() {
    setShowModal?.(false)
  }

  return <Page title="Home">
    {isLoggedIn || loading ? <div className="flex justify-around" style={{ height: '100vh', alignItems: 'center' }}>
      <div className="text-center">
        {message && <h5>{message}</h5>}
        <div className="spinner-border text-info" style={{ width: '5rem', height: '5rem', marginTop: '20px' }} role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div> :
      <div className="flex justify-around">
        <div className="max-w-md">
          <nav className="flex align-center justify-between p-2">
            <div className="flex align-center g-1">
              <img src="/assets/logo.svg" alt="xGames logo" width="60" />
              <p className="wide mb-0">
                <strong>{process.env.REACT_APP_GAME_NAME || "GamesFrenzy"}</strong>
              </p>
            </div>
            <div className="text-sm text-gray">
              <i>{process.env.REACT_APP_GAME_CTA || "Games, Apps, Videos & Community"} </i>
            </div>
          </nav>
          <main>
            <div id="game-jumbotron" className="flex h-24 w-full align-end">
              <div className="gradient-1 text-center stretch pt-8 -mb-1 pb-3">
                <p className="text-light text-gray text-xl">{process.env.REACT_APP_GAMES_BANNER_TEXT || "1000+ Games"}</p>
                <p className="text-2xl text-bold">{process.env.REACT_APP_GAMES_INTRO_TEXT || "Ready to Play"}</p>
              </div>
            </div>
            <div className="flex wrap bg-gray py-4 px-6 text-sm">
              {(process.env.REACT_APP_BANNER_TEXT_LIST || "No Ads,No In App Purchases,Unlimited Access,Up to 6 screens at once")
                .split(",")
                .map((v, index) => (
                  <div key={index} className="w-half flex align-center g-2">
                    <img src="/assets/icons/ok.svg" alt="ok" width="25" />
                    <p>{v}</p>
                  </div>
                ))}
            </div>
            <div className="flex wrap p-8 g-2 text-center">
              <p className="w-full">{process.env.REACT_APP_CLICK_TEXT || `Click on continue and and press "1" when prompted`}</p>
              {error && <p className="error-message">{error}</p>}
              <button className="w-full" onClick={() => { handleSubscription?.() }} disabled={btnLoading}>
                {btnLoading ? 'PLEASE WAIT' : 'CONTINUE'}
              </button>
            </div>
            <div className="px-12">
              <div className="text-sm">
                Accept the{" "}
                <a href="#" data-bs-toggle="modal" data-bs-target="#tncModal">
                  Terms & conditions
                </a>{" "}
                subscription service Ksh 48 daily
              </div>
            </div>
          </main>
        </div>

        <div className="modal fade" id="tncModal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <p>
                  By clicking on the action button, you confirm you are +18 and give your explicit consent to
                  {companyName} for the treatment and storage of your personal data as required by the European Union
                  General Data Protection Regulation. Get access to {serviceName} Subscription service Ksh10/day
                  Reference: {refNumber} Terms Apply {website} Support via {supportEmail} or call {serviceMobile}. STOP?
                  dial *456*20#. T&C's, Privacy Policy.
                </p>
                <button className="w-full" data-bs-toggle="modal" data-bs-target="#tncModal">
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Gift modal */}
        <GiftModal />

        <Modal show={showModal} onHide={handleModalClose} centered>
          <Modal.Body>
            <div className="text-center">
              <div style={{ marginBottom: '5px' }}>{message}</div>
              <button className="w-full" onClick={handleModalClose}>
                OK
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    }
  </Page>;
}